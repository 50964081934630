import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import cx from "classnames";
import { Row, Col, DatePicker } from "antd";
import { RangePickerValue } from "antd/es/date-picker/interface";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { UserContext } from "app/store/contexts/userContext";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import {
  DateFormats,
  PeriodsConst,
  PeriodsEnum,
} from "app/constants/dateConst";
import Select, { Option } from "app/components/Select/Select";
import styles from "./PageFilter.module.scss";
import { useFetchData, useFetchPaginatedData } from "app/hooks/hooks";
import { getPeriodFromToday } from "app/helpers/utilHelper";
import { getDataForUserInfoFilters } from "app/helpers/userHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

import {
  ListWrapperSiteDTO,
  SitesApiV2SitesGetRequest,
  ListWrapperCustomerDTO,
  CustomerApiV2CustomerGetRequest,
  UserType,
  TestRecordsApiV2TestRecordsRoutesGetRequest,
  ReaderType,
} from "@generated/v2";
import { sitesGet } from "app/api/v2/sitesApi";
import { customerGet } from "app/api/v2/customersApi";
import { testRecordsRouteGet } from "app/api/v2/testRecordsApi";

// Test Records Filters
import TestResultFilter from "app/screens/TestRecordsScreen/components/TestResultFilter/TestResultFilter";
import UsersFilter from "app/screens/TestRecordsScreen/components/UsersFilter/UsersFilter";

// Control Records Filters
import AnnotationFilter from "app/screens/ControlRecordsScreen/AnnotationFilter/AnnotationFilterFilter";

// User Filters
import UserFilter from "app/screens/UsersScreen/components/UserFilter/UserFilter";
import { readerTypes } from "app/constants/deviceConst";

const { RangePicker } = DatePicker;

// Order both customers and site by name ascending
const ORDER_BY = "name_asc";

interface Props {
  deviceFilter?: boolean;
  siteFilter?: boolean;
  dateFilter?: boolean;
  /** display routes filter */
  routesFilter?: boolean;
  /** display test results filter */
  testResultFilter?: boolean;
  /** display control results filter */
  annotationFilter?: boolean;
  usersFilter?: boolean;
  /**
   * Used for filtering on the users screen.
   * NOT TO BE CONFUSED WITH usersFilter.
   */
  userFilter?: boolean;
  /**
   * Extra content to be outputted on the
   * right side of the page filter
   */
  extra?: React.ReactNode;
}

const PageFilter = ({
  deviceFilter,
  siteFilter,
  dateFilter,
  routesFilter,
  testResultFilter,
  annotationFilter,
  usersFilter,
  userFilter,
  extra,
}: Props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const [localCustomerId, setLocalCustomerId] = useState<number>();
  const [allSites, setAllSites] = useState([]);

  const { userInfo, setUserInfo } = useContext(UserContext);
  const {
    customerId,
    siteId,
    period,
    dates,
    routes,
    testResult,
    controlResult,
    readerType,
  } = userInfo.filters;

  /** Get Customers data */
  const {
    data: customers,
    loading: customersLoading,
    refetch: getCustomers,
  } = useFetchPaginatedData<
    ListWrapperCustomerDTO,
    CustomerApiV2CustomerGetRequest
  >(customerGet, {
    shouldCallApi: hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]),
    params: {
      page: 1,
      order: ORDER_BY,
      pageSize: 10000,
    },
  });

  /** Get Sites data */
  const {
    data: sites,
    loading: sitesLoading,
    refetch: getSites,
  } = useFetchPaginatedData<ListWrapperSiteDTO, SitesApiV2SitesGetRequest>(
    sitesGet,
    {
      shouldCallApi:
        hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]) && !!customerId,
      params: {
        page: 1,
        customerId,
        order: ORDER_BY,
        pageSize: 10000,
      },
    }
  );

  /** Get routes for selected site/customer */
  const { data: routesData, loading: routesLoading } = useFetchData<
    string[],
    TestRecordsApiV2TestRecordsRoutesGetRequest
  >(testRecordsRouteGet, {
    shouldCallApi: !!siteId,
    params: {
      customerId,
      siteId,
    },
  });

  useEffect(() => {
    setUserInfo({
      refetchFilters: {
        refetchCustomer: getCustomers,
        refetchSite: getSites,
      },
    });
  }, [getCustomers, getSites, setUserInfo]);

  useEffect(() => {
    /** If no customer selected - use the first one in the list */
    if (!customerId && customers.length) {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          customerId: customers[0].id,
          customer: customers[0],
        },
      });
      setLocalCustomerId(customers[0].id);
    }
    /**
     * Expand the userInfo.filters with optional customer
     * and site name for Firebase analytics
     */
    // TODO: customerId should be refactored to use customer ACROSS ALL COMPONENTS
    if (customers.length && customerId) {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          customer: customers.find(entry => entry.id === customerId),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  /**
   * First time when page loads =>
   * - Set userInfo.filter site name and id
   * - when user type is site admin also set customer name and id
   * (Used for analytics)
   */
  useEffect(() => {
    /* applies for ch admin and customer admin: */
    if (siteId && allSites.length) {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          site: getDataForUserInfoFilters(siteId, allSites),
        },
      });
      /**
       *  detect if user type is site admin AND userInfo.filters.site is not set :
       * - also set customer name and id */
    } else if (
      siteId &&
      userInfo.type === UserType.SiteAdmin &&
      !userInfo.filters?.site?.name
    ) {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          site: {
            name: userInfo.site?.name,
            id: userInfo.site?.id,
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSites]);

  useEffect(() => {
    if (userInfo.siteId) {
      // Check if the siteId exists in the current sites list
      const siteExists = sites.some(site => site.id === userInfo.siteId);

      // Update the sites list only if the site doesn't exist
      if (!siteExists) {
        setAllSites([...sites, userInfo.site]);
      } else {
        setAllSites(sites);
      }
    } else {
      // If no siteId is provided, use the existing sites list
      setAllSites(sites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites]);

  /**
   * If customer ID has been set outside of the page filter.
   * Usually indicating that a new user has been created.
   */
  useEffect(() => {
    if (customerId !== localCustomerId && !!customerId && customers.length) {
      setLocalCustomerId(customerId);
      getCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  /**
   * Remove page parameter from query string, as the data set will have changed,
   * due to selection of new dates.
   */
  const resetPage = useCallback(() => {
    const currentSearch = qs.parse(location.search);

    if (!!currentSearch.page) {
      delete currentSearch.page;

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(currentSearch, {
          arrayFormat: "comma",
        }),
      });
    }
  }, [history, location.pathname, location.search]);

  const handlePeriodSelection = (period: PeriodsEnum) => {
    resetPage();
    if (!!period) {
      const type = period.toLowerCase().includes("days") ? "days" : "months";
      setUserInfo({
        filters: {
          ...userInfo.filters,
          period,
          dates: getPeriodFromToday(PeriodsConst[period], type),
        },
      });
    } else {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          period: PeriodsEnum.DAYS_28,
          dates: getPeriodFromToday(),
        },
      });
    }
  };

  const handleDateSelection = (dates: RangePickerValue) => {
    resetPage();
    if (dates.length > 0) {
      setUserInfo({
        filters: { ...userInfo.filters, dates: dates, period: undefined },
      });
    } else {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          dates: getPeriodFromToday(),
          period: PeriodsEnum.DAYS_28,
        },
      });
    }
  };

  const handleCustomerSelect = useCallback(
    (customerId: number | undefined) => {
      resetPage();
      setLocalCustomerId(customerId);
      setUserInfo({
        filters: {
          ...userInfo.filters,
          customerId,
          customer: customers.find(entry => entry.id === customerId),
          siteId: undefined,
          site: undefined,
          // clear users after changing customer
          userIds: [],
          // clear routes if new customer selected
          routes: [],
        },
      });
    },
    [customers, resetPage, setUserInfo, userInfo.filters]
  );

  const handleDeviceTypeSelect = useCallback(
    (value: ReaderType) => {
      resetPage();
      setUserInfo({
        filters: {
          ...userInfo.filters,
          readerType: value,
        },
      });
    },
    [resetPage, setUserInfo, userInfo.filters]
  );

  const handleSiteSelect = useCallback(
    (siteId: number) => {
      resetPage();
      setUserInfo({
        filters: {
          ...userInfo.filters,
          siteId,
          site: getDataForUserInfoFilters(siteId, allSites),
          // clear users after changing site
          userIds: [],
          // clear routes if new site selected
          routes: [],
        },
      });
    },
    [allSites, resetPage, setUserInfo, userInfo.filters]
  );

  const handleRoutesSelect = useCallback(
    (value: string[]) => {
      resetPage();
      setUserInfo({
        filters: {
          ...userInfo.filters,
          routes: value,
        },
      });
    },
    [resetPage, setUserInfo, userInfo.filters]
  );

  /**
   * Test result filter onChange handler
   *
   * @param {number} weight new test results filters weight
   */
  const handleTestResultFilterChange = (weight: number) => {
    resetPage();
    setUserInfo({
      filters: {
        ...userInfo.filters,
        testResult: weight,
      },
    });
  };

  /**
   * Control result filter onChange handler
   *
   * @param {number} weight new Control results filters weight
   */
  const handleAnnotationFilterChange = (weight: number) => {
    resetPage();
    setUserInfo({
      filters: {
        ...userInfo.filters,
        controlResult: weight,
      },
    });
  };

  return (
    <ErrorFallbackUI>
      <Row gutter={16}>
        <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_READ]}>
          <Col span={24} lg={12} xl={6}>
            <Select
              placeholder={t("pageFilter.selectCustomer")}
              className={styles.select}
              onChange={handleCustomerSelect}
              disabled={customersLoading}
              loading={customersLoading}
              value={
                customers.some(customer => customer.id === customerId)
                  ? customerId
                  : undefined
              }
            >
              {customers.map(customer => (
                <Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Permission>
        {siteFilter && (
          <Permission
            requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_READ]}
          >
            <Col span={24} lg={12} xl={6}>
              <Select
                placeholder={t("pageFilter.selectSite")}
                className={styles.select}
                onChange={handleSiteSelect}
                disabled={!customerId || sitesLoading}
                loading={sitesLoading}
                value={
                  allSites.some(site => site.id === siteId) ? siteId : undefined
                }
              >
                {allSites.map(site => (
                  <Option key={site.id} value={site.id}>
                    {site.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Permission>
        )}
        {deviceFilter && (
          <Permission
            requiredPermissions={[
              PermissionEnum.ACCOUNTS_CUSTOMER_READ,
              PermissionEnum.ACCOUNTS_SITE_READ,
            ]}
          >
            <Col span={24} lg={12} xl={6}>
              <Select
                placeholder={t("pageFilter.deviceType")}
                className={styles.select}
                onChange={handleDeviceTypeSelect}
                value={readerType}
              >
                {readerTypes.map(device => (
                  <Option key={device.key} value={device.key}>
                    {device.title}
                  </Option>
                ))}
              </Select>
            </Col>
          </Permission>
        )}

        {dateFilter && (
          <>
            <Col span={24} lg={24} xl={6}>
              <Select
                className={styles.select}
                placeholder={t("pageFilter.selectPeriod")}
                onChange={handlePeriodSelection}
                value={period}
              >
                {Object.keys(PeriodsEnum).map(key => (
                  <Option key={PeriodsEnum[key]} value={PeriodsEnum[key]}>
                    {t(`pageFilter.${PeriodsEnum[key]}`)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={24} lg={24} xl={6}>
              <RangePicker
                value={dates}
                format={DateFormats.DATE_DMY_NO_HYPHEN}
                separator="-"
                size="large"
                onChange={handleDateSelection}
                className={styles.select}
              />
            </Col>
          </>
        )}
        {routesFilter && (
          <Col span={24} lg={12} xl={6}>
            <Select
              placeholder={t("pageFilter.selectRoutes")}
              mode="multiple"
              className={styles.select}
              onChange={handleRoutesSelect}
              loading={routesLoading}
              disabled={!siteId}
              value={routesData?.filter(route => routes?.includes(route))}
            >
              {routesData
                ?.filter(route => !!route)
                .map(route => (
                  <Option key={route} value={route}>
                    {route}
                  </Option>
                ))}
            </Select>
          </Col>
        )}
        {testResultFilter && (
          <Col span={24} lg={12} xl={6}>
            <TestResultFilter
              disabled={!customerId}
              testResult={testResult!}
              onChange={handleTestResultFilterChange}
            />
          </Col>
        )}
        {annotationFilter && (
          <Col span={24} lg={12} xl={6}>
            <AnnotationFilter
              disabled={!customerId}
              controlResult={controlResult!}
              onChange={handleAnnotationFilterChange}
            />
          </Col>
        )}
        {usersFilter && (
          <Col span={24} lg={12} xl={6}>
            <UsersFilter />
          </Col>
        )}
        {userFilter && (
          <Col span={24} lg={12} xl={6}>
            <UserFilter />
          </Col>
        )}
        {extra && (
          <Col
            className={cx({ [styles.extra]: !dateFilter })}
            span={24}
            lg={24}
            xl={dateFilter ? 6 : 12}
          >
            <div className={styles.extraContent}>{extra}</div>
          </Col>
        )}
      </Row>
    </ErrorFallbackUI>
  );
};

export default memo(PageFilter);
